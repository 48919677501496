<template>
  <img
    v-if="!errorImg"
    v-bind="$attrs"
    :id="idImg"
    :src="fullSrc()"
    :class="{ 'lazy-loading': !isLoaded }"
    :alt="dataAlt"
    :width="width"
    :height="height"
    :style="{ borderRadius: borderRadius && `${borderRadius}px` }"
    @error="showAltImage"
  />
</template>

<script setup lang="ts">
const { $config } = useNuxtApp()

const props = defineProps({
  showDefault: {
    type: Boolean,
    default: false
  },
  width: {
    type: String,
    default: undefined
  },
  height: {
    type: String,
    default: undefined
  },
  borderRadius: {
    type: String,
    default: undefined
  },
  lazy: {
    type: Boolean,
    default: false
  },
  src: {
    type: String,
    default: ''
  },
  alt: {
    type: String,
    default: ''
  },
  defaultSrc: {
    type: String,
    default: ''
  }
})
const isLoaded = ref(!props.lazy)
const errorImg = ref(false)
const fullSrc = () => {
  return !/^(\s?)http(s?):\/\//.test(props.src) && !/^\/\//.test(props.src)
    ? new URL(props.src, $config.STATIC_BASE_URL).toString()
    : props.src
}
const showAltImage = (event: Event) => {
  if (props.showDefault) {
    errorImg.value = true
  }
  const target = event.target as HTMLImageElement
  target.src = props.defaultSrc || `${$config.STATIC_BASE_URL}/assets/brand/logo.svg`
}

const dataAlt = computed(() => {
  return props.alt ? `${props.alt} ${$config.public.BRANCH_NAME}` : $config.public.BRANCH_NAME
})

const idImg = computed(() => {
  return props.alt ? `${props.alt}-image` : $config.public.BRANCH_NAME
})

const route = useRoute()
watch(route, () => {
  errorImg.value = false
})

onMounted(() => {
  if (!props.src) {
    return
  }

  if (!props.lazy) {
    isLoaded.value = true
  }

  const img = new Image()
  img.src = fullSrc()
  img.onload = (evt) => {
    setTimeout(() => {
      isLoaded.value = true
    }, 100)
  }
})
</script>
<style lang="scss" scoped src="~/assets/scss/components/common/base-img.scss"></style>

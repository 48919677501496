import { default as bankOicdIENANkMeta } from "D:/Vue/build/ps24-web/pages/account/bank.vue?macro=true";
import { default as bonusXvuJMlMyQ4Meta } from "D:/Vue/build/ps24-web/pages/account/bonus.vue?macro=true";
import { default as bankViElm87N0tMeta } from "D:/Vue/build/ps24-web/pages/account/deposit/bank.vue?macro=true";
import { default as cardFwhKXQSBw1Meta } from "D:/Vue/build/ps24-web/pages/account/deposit/card.vue?macro=true";
import { default as codepayr93ItN0OfTMeta } from "D:/Vue/build/ps24-web/pages/account/deposit/codepay.vue?macro=true";
import { default as codepay2SXxRhZWDzyMeta } from "D:/Vue/build/ps24-web/pages/account/deposit/codepay2.vue?macro=true";
import { default as cryptooS6QwtukggMeta } from "D:/Vue/build/ps24-web/pages/account/deposit/crypto.vue?macro=true";
import { default as ewalletiUf7l6SMJ1Meta } from "D:/Vue/build/ps24-web/pages/account/deposit/ewallet.vue?macro=true";
import { default as betA9WLTyezxOMeta } from "D:/Vue/build/ps24-web/pages/account/history/bet.vue?macro=true";
import { default as transactionjrrxkM4DC7Meta } from "D:/Vue/build/ps24-web/pages/account/history/transaction.vue?macro=true";
import { default as historyK1e9YG5QZ7Meta } from "D:/Vue/build/ps24-web/pages/account/history.vue?macro=true";
import { default as profileriA3Rffm5kMeta } from "D:/Vue/build/ps24-web/pages/account/profile.vue?macro=true";
import { default as trading_45p2pUXjxD71T5FMeta } from "D:/Vue/build/ps24-web/pages/account/trading-p2p.vue?macro=true";
import { default as bankVBWZVduPUGMeta } from "D:/Vue/build/ps24-web/pages/account/withdraw/bank.vue?macro=true";
import { default as cardOHLfAHbwi8Meta } from "D:/Vue/build/ps24-web/pages/account/withdraw/card.vue?macro=true";
import { default as cryptonyppnibRrbMeta } from "D:/Vue/build/ps24-web/pages/account/withdraw/crypto.vue?macro=true";
import { default as accountV1kbsENwRqMeta } from "D:/Vue/build/ps24-web/pages/account.vue?macro=true";
import { default as base_45buttonEoXDXucQtRMeta } from "D:/Vue/build/ps24-web/pages/dev/base-button.vue?macro=true";
import { default as base_45count_45downez0qTVnrEpMeta } from "D:/Vue/build/ps24-web/pages/dev/base-count-down.vue?macro=true";
import { default as base_45dropdown_45radiozfVkHe6Jz1Meta } from "D:/Vue/build/ps24-web/pages/dev/base-dropdown-radio.vue?macro=true";
import { default as base_45imgt33T5UDgLgMeta } from "D:/Vue/build/ps24-web/pages/dev/base-img.vue?macro=true";
import { default as base_45inputusjSRDXnbCMeta } from "D:/Vue/build/ps24-web/pages/dev/base-input.vue?macro=true";
import { default as base_45loadingdSdzxfx3IJMeta } from "D:/Vue/build/ps24-web/pages/dev/base-loading.vue?macro=true";
import { default as base_45modalHjh1Ju9PFWMeta } from "D:/Vue/build/ps24-web/pages/dev/base-modal.vue?macro=true";
import { default as base_45money_45inputzgVET03h4QMeta } from "D:/Vue/build/ps24-web/pages/dev/base-money-input.vue?macro=true";
import { default as base_45paginationdVf1NKDI7GMeta } from "D:/Vue/build/ps24-web/pages/dev/base-pagination.vue?macro=true";
import { default as base_45select_45bankkNdAcEHeQvMeta } from "D:/Vue/build/ps24-web/pages/dev/base-select-bank.vue?macro=true";
import { default as game_45itemNTujHiesqDMeta } from "D:/Vue/build/ps24-web/pages/dev/game-item.vue?macro=true";
import { default as indexNinmlN1jnoMeta } from "D:/Vue/build/ps24-web/pages/dev/index.vue?macro=true";
import { default as _91type_93oOAC0kLOMqMeta } from "D:/Vue/build/ps24-web/pages/game/[type].vue?macro=true";
import { default as gameCJ4oVe3VpmMeta } from "D:/Vue/build/ps24-web/pages/game.vue?macro=true";
import { default as _91alias_93LZWODRs8ezMeta } from "D:/Vue/build/ps24-web/pages/gioi-thieu/[alias].vue?macro=true";
import { default as gioi_45thieu0zs1TEUOqFMeta } from "D:/Vue/build/ps24-web/pages/gioi-thieu.vue?macro=true";
import { default as _91alias_93XKIqBHzGBmMeta } from "D:/Vue/build/ps24-web/pages/huong-dan/[alias].vue?macro=true";
import { default as huong_45danoMAG4pKONMMeta } from "D:/Vue/build/ps24-web/pages/huong-dan.vue?macro=true";
import { default as index09lxN6dJnmMeta } from "D:/Vue/build/ps24-web/pages/index.vue?macro=true";
import { default as _91id_93AebR0BUZRuMeta } from "D:/Vue/build/ps24-web/pages/khuyen-mai/[id].vue?macro=true";
import { default as indexwbPCHrvIyDMeta } from "D:/Vue/build/ps24-web/pages/khuyen-mai/index.vue?macro=true";
import { default as _91id_93hTHo82xsHbMeta } from "D:/Vue/build/ps24-web/pages/khuyen-mai/the-loai/[id].vue?macro=true";
import { default as vipkYo2Mkzn1UMeta } from "D:/Vue/build/ps24-web/pages/khuyen-mai/vip.vue?macro=true";
import { default as khuyen_45maizdTmntoy3QMeta } from "D:/Vue/build/ps24-web/pages/khuyen-mai.vue?macro=true";
import { default as _91type_93IVoJc2d5GnMeta } from "D:/Vue/build/ps24-web/pages/livecasino/[type].vue?macro=true";
import { default as livecasino84rOSX45LBMeta } from "D:/Vue/build/ps24-web/pages/livecasino.vue?macro=true";
import { default as reset_45passwordnOIqE3ONnEMeta } from "D:/Vue/build/ps24-web/pages/reset-password.vue?macro=true";
import { default as _91id_930qbmWCKtd0Meta } from "D:/Vue/build/ps24-web/pages/the-thao/[id].vue?macro=true";
import { default as indexwevNtV4wqpMeta } from "D:/Vue/build/ps24-web/pages/the-thao/index.vue?macro=true";
import { default as indexGF5PGI5wUrMeta } from "D:/Vue/build/ps24-web/pages/the-thao/lich-thi-dau/detail/index.vue?macro=true";
import { default as index0cGCoquTh7Meta } from "D:/Vue/build/ps24-web/pages/the-thao/lich-thi-dau/index.vue?macro=true";
import { default as _91alias_939vh2dTgCcjMeta } from "D:/Vue/build/ps24-web/pages/tin-tuc/[alias].vue?macro=true";
import { default as indextV4atiffwzMeta } from "D:/Vue/build/ps24-web/pages/tin-tuc/index.vue?macro=true";
import { default as _91Slug_93miF3kh8XHEMeta } from "D:/Vue/build/ps24-web/pages/tin-tuc/the-loai/[Slug].vue?macro=true";
import { default as tin_45tucgkxXVn1KWVMeta } from "D:/Vue/build/ps24-web/pages/tin-tuc.vue?macro=true";
import { default as verify_45mailK8veaeThUHMeta } from "D:/Vue/build/ps24-web/pages/verify-mail.vue?macro=true";
export default [
  {
    name: accountV1kbsENwRqMeta?.name ?? "account",
    path: accountV1kbsENwRqMeta?.path ?? "/account",
    children: [
  {
    name: bankOicdIENANkMeta?.name ?? "account-bank",
    path: bankOicdIENANkMeta?.path ?? "bank",
    meta: bankOicdIENANkMeta || {},
    alias: bankOicdIENANkMeta?.alias || [],
    redirect: bankOicdIENANkMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/bank.vue").then(m => m.default || m)
  },
  {
    name: bonusXvuJMlMyQ4Meta?.name ?? "account-bonus",
    path: bonusXvuJMlMyQ4Meta?.path ?? "bonus",
    meta: bonusXvuJMlMyQ4Meta || {},
    alias: bonusXvuJMlMyQ4Meta?.alias || [],
    redirect: bonusXvuJMlMyQ4Meta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/bonus.vue").then(m => m.default || m)
  },
  {
    name: bankViElm87N0tMeta?.name ?? "account-deposit-bank",
    path: bankViElm87N0tMeta?.path ?? "deposit/bank",
    meta: bankViElm87N0tMeta || {},
    alias: bankViElm87N0tMeta?.alias || [],
    redirect: bankViElm87N0tMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/deposit/bank.vue").then(m => m.default || m)
  },
  {
    name: cardFwhKXQSBw1Meta?.name ?? "account-deposit-card",
    path: cardFwhKXQSBw1Meta?.path ?? "deposit/card",
    meta: cardFwhKXQSBw1Meta || {},
    alias: cardFwhKXQSBw1Meta?.alias || [],
    redirect: cardFwhKXQSBw1Meta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/deposit/card.vue").then(m => m.default || m)
  },
  {
    name: codepayr93ItN0OfTMeta?.name ?? "account-deposit-codepay",
    path: codepayr93ItN0OfTMeta?.path ?? "deposit/codepay",
    meta: codepayr93ItN0OfTMeta || {},
    alias: codepayr93ItN0OfTMeta?.alias || [],
    redirect: codepayr93ItN0OfTMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/deposit/codepay.vue").then(m => m.default || m)
  },
  {
    name: codepay2SXxRhZWDzyMeta?.name ?? "account-deposit-codepay2",
    path: codepay2SXxRhZWDzyMeta?.path ?? "deposit/codepay2",
    meta: codepay2SXxRhZWDzyMeta || {},
    alias: codepay2SXxRhZWDzyMeta?.alias || [],
    redirect: codepay2SXxRhZWDzyMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/deposit/codepay2.vue").then(m => m.default || m)
  },
  {
    name: cryptooS6QwtukggMeta?.name ?? "account-deposit-crypto",
    path: cryptooS6QwtukggMeta?.path ?? "deposit/crypto",
    meta: cryptooS6QwtukggMeta || {},
    alias: cryptooS6QwtukggMeta?.alias || [],
    redirect: cryptooS6QwtukggMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/deposit/crypto.vue").then(m => m.default || m)
  },
  {
    name: ewalletiUf7l6SMJ1Meta?.name ?? "account-deposit-ewallet",
    path: ewalletiUf7l6SMJ1Meta?.path ?? "deposit/ewallet",
    meta: ewalletiUf7l6SMJ1Meta || {},
    alias: ewalletiUf7l6SMJ1Meta?.alias || [],
    redirect: ewalletiUf7l6SMJ1Meta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/deposit/ewallet.vue").then(m => m.default || m)
  },
  {
    name: historyK1e9YG5QZ7Meta?.name ?? "account-history",
    path: historyK1e9YG5QZ7Meta?.path ?? "history",
    children: [
  {
    name: betA9WLTyezxOMeta?.name ?? "account-history-bet",
    path: betA9WLTyezxOMeta?.path ?? "bet",
    meta: betA9WLTyezxOMeta || {},
    alias: betA9WLTyezxOMeta?.alias || [],
    redirect: betA9WLTyezxOMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/history/bet.vue").then(m => m.default || m)
  },
  {
    name: transactionjrrxkM4DC7Meta?.name ?? "account-history-transaction",
    path: transactionjrrxkM4DC7Meta?.path ?? "transaction",
    meta: transactionjrrxkM4DC7Meta || {},
    alias: transactionjrrxkM4DC7Meta?.alias || [],
    redirect: transactionjrrxkM4DC7Meta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/history/transaction.vue").then(m => m.default || m)
  }
],
    meta: historyK1e9YG5QZ7Meta || {},
    alias: historyK1e9YG5QZ7Meta?.alias || [],
    redirect: historyK1e9YG5QZ7Meta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: profileriA3Rffm5kMeta?.name ?? "account-profile",
    path: profileriA3Rffm5kMeta?.path ?? "profile",
    meta: profileriA3Rffm5kMeta || {},
    alias: profileriA3Rffm5kMeta?.alias || [],
    redirect: profileriA3Rffm5kMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: trading_45p2pUXjxD71T5FMeta?.name ?? "account-trading-p2p",
    path: trading_45p2pUXjxD71T5FMeta?.path ?? "trading-p2p",
    meta: trading_45p2pUXjxD71T5FMeta || {},
    alias: trading_45p2pUXjxD71T5FMeta?.alias || [],
    redirect: trading_45p2pUXjxD71T5FMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/trading-p2p.vue").then(m => m.default || m)
  },
  {
    name: bankVBWZVduPUGMeta?.name ?? "account-withdraw-bank",
    path: bankVBWZVduPUGMeta?.path ?? "withdraw/bank",
    meta: bankVBWZVduPUGMeta || {},
    alias: bankVBWZVduPUGMeta?.alias || [],
    redirect: bankVBWZVduPUGMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/withdraw/bank.vue").then(m => m.default || m)
  },
  {
    name: cardOHLfAHbwi8Meta?.name ?? "account-withdraw-card",
    path: cardOHLfAHbwi8Meta?.path ?? "withdraw/card",
    meta: cardOHLfAHbwi8Meta || {},
    alias: cardOHLfAHbwi8Meta?.alias || [],
    redirect: cardOHLfAHbwi8Meta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/withdraw/card.vue").then(m => m.default || m)
  },
  {
    name: cryptonyppnibRrbMeta?.name ?? "account-withdraw-crypto",
    path: cryptonyppnibRrbMeta?.path ?? "withdraw/crypto",
    meta: cryptonyppnibRrbMeta || {},
    alias: cryptonyppnibRrbMeta?.alias || [],
    redirect: cryptonyppnibRrbMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account/withdraw/crypto.vue").then(m => m.default || m)
  }
],
    meta: accountV1kbsENwRqMeta || {},
    alias: accountV1kbsENwRqMeta?.alias || [],
    redirect: accountV1kbsENwRqMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: base_45buttonEoXDXucQtRMeta?.name ?? "dev-base-button",
    path: base_45buttonEoXDXucQtRMeta?.path ?? "/dev/base-button",
    meta: base_45buttonEoXDXucQtRMeta || {},
    alias: base_45buttonEoXDXucQtRMeta?.alias || [],
    redirect: base_45buttonEoXDXucQtRMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/dev/base-button.vue").then(m => m.default || m)
  },
  {
    name: base_45count_45downez0qTVnrEpMeta?.name ?? "dev-base-count-down",
    path: base_45count_45downez0qTVnrEpMeta?.path ?? "/dev/base-count-down",
    meta: base_45count_45downez0qTVnrEpMeta || {},
    alias: base_45count_45downez0qTVnrEpMeta?.alias || [],
    redirect: base_45count_45downez0qTVnrEpMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/dev/base-count-down.vue").then(m => m.default || m)
  },
  {
    name: base_45dropdown_45radiozfVkHe6Jz1Meta?.name ?? "dev-base-dropdown-radio",
    path: base_45dropdown_45radiozfVkHe6Jz1Meta?.path ?? "/dev/base-dropdown-radio",
    meta: base_45dropdown_45radiozfVkHe6Jz1Meta || {},
    alias: base_45dropdown_45radiozfVkHe6Jz1Meta?.alias || [],
    redirect: base_45dropdown_45radiozfVkHe6Jz1Meta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/dev/base-dropdown-radio.vue").then(m => m.default || m)
  },
  {
    name: base_45imgt33T5UDgLgMeta?.name ?? "dev-base-img",
    path: base_45imgt33T5UDgLgMeta?.path ?? "/dev/base-img",
    meta: base_45imgt33T5UDgLgMeta || {},
    alias: base_45imgt33T5UDgLgMeta?.alias || [],
    redirect: base_45imgt33T5UDgLgMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/dev/base-img.vue").then(m => m.default || m)
  },
  {
    name: base_45inputusjSRDXnbCMeta?.name ?? "dev-base-input",
    path: base_45inputusjSRDXnbCMeta?.path ?? "/dev/base-input",
    meta: base_45inputusjSRDXnbCMeta || {},
    alias: base_45inputusjSRDXnbCMeta?.alias || [],
    redirect: base_45inputusjSRDXnbCMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/dev/base-input.vue").then(m => m.default || m)
  },
  {
    name: base_45loadingdSdzxfx3IJMeta?.name ?? "dev-base-loading",
    path: base_45loadingdSdzxfx3IJMeta?.path ?? "/dev/base-loading",
    meta: base_45loadingdSdzxfx3IJMeta || {},
    alias: base_45loadingdSdzxfx3IJMeta?.alias || [],
    redirect: base_45loadingdSdzxfx3IJMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/dev/base-loading.vue").then(m => m.default || m)
  },
  {
    name: base_45modalHjh1Ju9PFWMeta?.name ?? "dev-base-modal",
    path: base_45modalHjh1Ju9PFWMeta?.path ?? "/dev/base-modal",
    meta: base_45modalHjh1Ju9PFWMeta || {},
    alias: base_45modalHjh1Ju9PFWMeta?.alias || [],
    redirect: base_45modalHjh1Ju9PFWMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/dev/base-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45money_45inputzgVET03h4QMeta?.name ?? "dev-base-money-input",
    path: base_45money_45inputzgVET03h4QMeta?.path ?? "/dev/base-money-input",
    meta: base_45money_45inputzgVET03h4QMeta || {},
    alias: base_45money_45inputzgVET03h4QMeta?.alias || [],
    redirect: base_45money_45inputzgVET03h4QMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/dev/base-money-input.vue").then(m => m.default || m)
  },
  {
    name: base_45paginationdVf1NKDI7GMeta?.name ?? "dev-base-pagination",
    path: base_45paginationdVf1NKDI7GMeta?.path ?? "/dev/base-pagination",
    meta: base_45paginationdVf1NKDI7GMeta || {},
    alias: base_45paginationdVf1NKDI7GMeta?.alias || [],
    redirect: base_45paginationdVf1NKDI7GMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/dev/base-pagination.vue").then(m => m.default || m)
  },
  {
    name: base_45select_45bankkNdAcEHeQvMeta?.name ?? "dev-base-select-bank",
    path: base_45select_45bankkNdAcEHeQvMeta?.path ?? "/dev/base-select-bank",
    meta: base_45select_45bankkNdAcEHeQvMeta || {},
    alias: base_45select_45bankkNdAcEHeQvMeta?.alias || [],
    redirect: base_45select_45bankkNdAcEHeQvMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/dev/base-select-bank.vue").then(m => m.default || m)
  },
  {
    name: game_45itemNTujHiesqDMeta?.name ?? "dev-game-item",
    path: game_45itemNTujHiesqDMeta?.path ?? "/dev/game-item",
    meta: game_45itemNTujHiesqDMeta || {},
    alias: game_45itemNTujHiesqDMeta?.alias || [],
    redirect: game_45itemNTujHiesqDMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/dev/game-item.vue").then(m => m.default || m)
  },
  {
    name: indexNinmlN1jnoMeta?.name ?? "dev",
    path: indexNinmlN1jnoMeta?.path ?? "/dev",
    meta: indexNinmlN1jnoMeta || {},
    alias: indexNinmlN1jnoMeta?.alias || [],
    redirect: indexNinmlN1jnoMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: gameCJ4oVe3VpmMeta?.name ?? "game",
    path: gameCJ4oVe3VpmMeta?.path ?? "/game",
    children: [
  {
    name: _91type_93oOAC0kLOMqMeta?.name ?? "game-type",
    path: _91type_93oOAC0kLOMqMeta?.path ?? ":type",
    meta: _91type_93oOAC0kLOMqMeta || {},
    alias: _91type_93oOAC0kLOMqMeta?.alias || [],
    redirect: _91type_93oOAC0kLOMqMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/game/[type].vue").then(m => m.default || m)
  }
],
    meta: gameCJ4oVe3VpmMeta || {},
    alias: gameCJ4oVe3VpmMeta?.alias || [],
    redirect: gameCJ4oVe3VpmMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/game.vue").then(m => m.default || m)
  },
  {
    name: gioi_45thieu0zs1TEUOqFMeta?.name ?? "gioi-thieu",
    path: gioi_45thieu0zs1TEUOqFMeta?.path ?? "/gioi-thieu",
    children: [
  {
    name: _91alias_93LZWODRs8ezMeta?.name ?? "gioi-thieu-alias",
    path: _91alias_93LZWODRs8ezMeta?.path ?? ":alias",
    meta: _91alias_93LZWODRs8ezMeta || {},
    alias: _91alias_93LZWODRs8ezMeta?.alias || [],
    redirect: _91alias_93LZWODRs8ezMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/gioi-thieu/[alias].vue").then(m => m.default || m)
  }
],
    meta: gioi_45thieu0zs1TEUOqFMeta || {},
    alias: gioi_45thieu0zs1TEUOqFMeta?.alias || [],
    redirect: gioi_45thieu0zs1TEUOqFMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/gioi-thieu.vue").then(m => m.default || m)
  },
  {
    name: huong_45danoMAG4pKONMMeta?.name ?? "huong-dan",
    path: huong_45danoMAG4pKONMMeta?.path ?? "/huong-dan",
    children: [
  {
    name: _91alias_93XKIqBHzGBmMeta?.name ?? "huong-dan-alias",
    path: _91alias_93XKIqBHzGBmMeta?.path ?? ":alias",
    meta: _91alias_93XKIqBHzGBmMeta || {},
    alias: _91alias_93XKIqBHzGBmMeta?.alias || [],
    redirect: _91alias_93XKIqBHzGBmMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/huong-dan/[alias].vue").then(m => m.default || m)
  }
],
    meta: huong_45danoMAG4pKONMMeta || {},
    alias: huong_45danoMAG4pKONMMeta?.alias || [],
    redirect: huong_45danoMAG4pKONMMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/huong-dan.vue").then(m => m.default || m)
  },
  {
    name: index09lxN6dJnmMeta?.name ?? "index",
    path: index09lxN6dJnmMeta?.path ?? "/",
    meta: index09lxN6dJnmMeta || {},
    alias: index09lxN6dJnmMeta?.alias || [],
    redirect: index09lxN6dJnmMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/index.vue").then(m => m.default || m)
  },
  {
    path: khuyen_45maizdTmntoy3QMeta?.path ?? "/khuyen-mai",
    children: [
  {
    name: _91id_93AebR0BUZRuMeta?.name ?? "khuyen-mai-id",
    path: _91id_93AebR0BUZRuMeta?.path ?? ":id",
    meta: _91id_93AebR0BUZRuMeta || {},
    alias: _91id_93AebR0BUZRuMeta?.alias || [],
    redirect: _91id_93AebR0BUZRuMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/khuyen-mai/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwbPCHrvIyDMeta?.name ?? "khuyen-mai",
    path: indexwbPCHrvIyDMeta?.path ?? "",
    meta: indexwbPCHrvIyDMeta || {},
    alias: indexwbPCHrvIyDMeta?.alias || [],
    redirect: indexwbPCHrvIyDMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/khuyen-mai/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93hTHo82xsHbMeta?.name ?? "khuyen-mai-the-loai-id",
    path: _91id_93hTHo82xsHbMeta?.path ?? "the-loai/:id",
    meta: _91id_93hTHo82xsHbMeta || {},
    alias: _91id_93hTHo82xsHbMeta?.alias || [],
    redirect: _91id_93hTHo82xsHbMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/khuyen-mai/the-loai/[id].vue").then(m => m.default || m)
  },
  {
    name: vipkYo2Mkzn1UMeta?.name ?? "khuyen-mai-vip",
    path: vipkYo2Mkzn1UMeta?.path ?? "vip",
    meta: vipkYo2Mkzn1UMeta || {},
    alias: vipkYo2Mkzn1UMeta?.alias || [],
    redirect: vipkYo2Mkzn1UMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/khuyen-mai/vip.vue").then(m => m.default || m)
  }
],
    name: khuyen_45maizdTmntoy3QMeta?.name ?? undefined,
    meta: khuyen_45maizdTmntoy3QMeta || {},
    alias: khuyen_45maizdTmntoy3QMeta?.alias || [],
    redirect: khuyen_45maizdTmntoy3QMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/khuyen-mai.vue").then(m => m.default || m)
  },
  {
    name: livecasino84rOSX45LBMeta?.name ?? "livecasino",
    path: livecasino84rOSX45LBMeta?.path ?? "/livecasino",
    children: [
  {
    name: _91type_93IVoJc2d5GnMeta?.name ?? "livecasino-type",
    path: _91type_93IVoJc2d5GnMeta?.path ?? ":type",
    meta: _91type_93IVoJc2d5GnMeta || {},
    alias: _91type_93IVoJc2d5GnMeta?.alias || [],
    redirect: _91type_93IVoJc2d5GnMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/livecasino/[type].vue").then(m => m.default || m)
  }
],
    meta: livecasino84rOSX45LBMeta || {},
    alias: livecasino84rOSX45LBMeta?.alias || [],
    redirect: livecasino84rOSX45LBMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/livecasino.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordnOIqE3ONnEMeta?.name ?? "reset-password",
    path: reset_45passwordnOIqE3ONnEMeta?.path ?? "/reset-password",
    meta: reset_45passwordnOIqE3ONnEMeta || {},
    alias: reset_45passwordnOIqE3ONnEMeta?.alias || [],
    redirect: reset_45passwordnOIqE3ONnEMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _91id_930qbmWCKtd0Meta?.name ?? "the-thao-id",
    path: _91id_930qbmWCKtd0Meta?.path ?? "/the-thao/:id",
    meta: _91id_930qbmWCKtd0Meta || {},
    alias: _91id_930qbmWCKtd0Meta?.alias || [],
    redirect: _91id_930qbmWCKtd0Meta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/the-thao/[id].vue").then(m => m.default || m)
  },
  {
    name: indexwevNtV4wqpMeta?.name ?? "the-thao",
    path: indexwevNtV4wqpMeta?.path ?? "/the-thao",
    meta: indexwevNtV4wqpMeta || {},
    alias: indexwevNtV4wqpMeta?.alias || [],
    redirect: indexwevNtV4wqpMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/the-thao/index.vue").then(m => m.default || m)
  },
  {
    name: indexGF5PGI5wUrMeta?.name ?? "the-thao-lich-thi-dau-detail",
    path: indexGF5PGI5wUrMeta?.path ?? "/the-thao/lich-thi-dau/detail",
    meta: indexGF5PGI5wUrMeta || {},
    alias: indexGF5PGI5wUrMeta?.alias || [],
    redirect: indexGF5PGI5wUrMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/the-thao/lich-thi-dau/detail/index.vue").then(m => m.default || m)
  },
  {
    name: index0cGCoquTh7Meta?.name ?? "the-thao-lich-thi-dau",
    path: index0cGCoquTh7Meta?.path ?? "/the-thao/lich-thi-dau",
    meta: index0cGCoquTh7Meta || {},
    alias: index0cGCoquTh7Meta?.alias || [],
    redirect: index0cGCoquTh7Meta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/the-thao/lich-thi-dau/index.vue").then(m => m.default || m)
  },
  {
    path: tin_45tucgkxXVn1KWVMeta?.path ?? "/tin-tuc",
    children: [
  {
    name: _91alias_939vh2dTgCcjMeta?.name ?? "tin-tuc-alias",
    path: _91alias_939vh2dTgCcjMeta?.path ?? ":alias",
    meta: _91alias_939vh2dTgCcjMeta || {},
    alias: _91alias_939vh2dTgCcjMeta?.alias || [],
    redirect: _91alias_939vh2dTgCcjMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/tin-tuc/[alias].vue").then(m => m.default || m)
  },
  {
    name: indextV4atiffwzMeta?.name ?? "tin-tuc",
    path: indextV4atiffwzMeta?.path ?? "",
    meta: indextV4atiffwzMeta || {},
    alias: indextV4atiffwzMeta?.alias || [],
    redirect: indextV4atiffwzMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91Slug_93miF3kh8XHEMeta?.name ?? "tin-tuc-the-loai-Slug",
    path: _91Slug_93miF3kh8XHEMeta?.path ?? "the-loai/:Slug",
    meta: _91Slug_93miF3kh8XHEMeta || {},
    alias: _91Slug_93miF3kh8XHEMeta?.alias || [],
    redirect: _91Slug_93miF3kh8XHEMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/tin-tuc/the-loai/[Slug].vue").then(m => m.default || m)
  }
],
    name: tin_45tucgkxXVn1KWVMeta?.name ?? undefined,
    meta: tin_45tucgkxXVn1KWVMeta || {},
    alias: tin_45tucgkxXVn1KWVMeta?.alias || [],
    redirect: tin_45tucgkxXVn1KWVMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/tin-tuc.vue").then(m => m.default || m)
  },
  {
    name: verify_45mailK8veaeThUHMeta?.name ?? "verify-mail",
    path: verify_45mailK8veaeThUHMeta?.path ?? "/verify-mail",
    meta: verify_45mailK8veaeThUHMeta || {},
    alias: verify_45mailK8veaeThUHMeta?.alias || [],
    redirect: verify_45mailK8veaeThUHMeta?.redirect || undefined,
    component: () => import("D:/Vue/build/ps24-web/pages/verify-mail.vue").then(m => m.default || m)
  }
]